import React from "react";
import { graphql, PageProps } from "gatsby";

import { CategoryT } from "@faq/types";

import { toCategory } from "@faq/utils/routes";

import Layout from "@faq/components/Layout";
import Block from "@faq/components/Block";

type DataProps = {
  allStrapiCategory: {
    nodes: CategoryT[];
  };
};

const Homepage: React.FC<PageProps<DataProps>> = ({
  data: { allStrapiCategory },
}) => {
  return (
    <Layout>
      {allStrapiCategory.nodes.map(
        ({ title, description, slug, articles }, i) => (
          <Block
            key={i}
            to={toCategory(slug)}
            title={title}
            content={description}
            articlesNb={articles.length}
          />
        )
      )}
    </Layout>
  );
};

export default Homepage;

export const pageQuery = graphql`
  query HOMEPAGE_QUERY {
    allStrapiCategory(sort: { fields: position }) {
      nodes {
        title
        description
        slug
        articles {
          id
        }
      }
    }
  }
`;
